import React from 'react'
import Layout from '../components/layouts/layout'
import Services from '../components/blocs/services'
import ColorStripe from '../components/blocs/color-stripe'
import Contact from '../components/blocs/contact'
import desktopBackgroundImage from '../images/banner-guillaume-desktop.jpg'
import mobileBackgroundImage from '../images/banner-guillaume-mobile.jpg'
import {Hero} from '../components/hero/hero'
import {Colors} from '../colors'
import * as styles from '../components/blocs/contact.module.scss'
import {useLayoutInformation} from '../hooks/useLayoutInformation'
import {PageRow} from '../components/page-row/page-row'

export const IndexPage: React.FC = () => {
    const {isMobile, secondaryColor} = useLayoutInformation()

    return (
        <Layout titleIntlId="title">
            <Hero
                backgroundImageUri={isMobile ? mobileBackgroundImage : desktopBackgroundImage}
                message="Besoin de prendre des décisions financières éclairées?"
                buttonColor={secondaryColor}
            />
            <PageRow fullWidth>
                <Services overlapPreviousElement={!isMobile}/>
            </PageRow>
            <ColorStripe
                content={<p>Vivacité d'esprit - Crédibilité - Générosité - Curiosité</p>}
                responsiveContent={
                    <p>
                        Vivacité d'esprit <br/>
                        Crédibilité <br/>
                        Générosité <br/>
                        Curiosité
                    </p>
                }
            />
            <div
                id="contact"
                className={styles.container}
                style={{
                    backgroundColor: Colors.lightBlue,
                }}
            >
                <Contact/>
            </div>
        </Layout>
    )
}

export default IndexPage
